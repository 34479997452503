/*Chandra Vamsi Krishna Alla 1002170054 Naga Manasa Bandaru : 1002195819 Venkata Harshitha Bathala : 1002204412 Pranav Subrahmanyeswararao Damu : 1002158458*/


.admin-dashboard {
    padding: 20px;
  }
  
  .stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .stats div {
    text-align: center;
  }
  
  .chart {
    max-width: 600px;
    margin: 0 auto;
  }
