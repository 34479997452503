/* src/components/Auth/HomePage.css */
/*Chandra Vamsi Krishna Alla 1002170054 Naga Manasa Bandaru : 1002195819 Venkata Harshitha Bathala : 1002204412 Pranav Subrahmanyeswararao Damu : 1002158458*/
.home-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 120px; /* Adjusted padding to account for fixed navbar */
}

.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    margin-top: 40px; /* Moved down to start from the center */
}

.header {
    width: 47ch;
    animation: typing 2s steps(47), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    font-family: monospace;
    font-size: 2em;
    margin-bottom: 10px;
}

.sub-header {
    width: 47ch;
    animation: typing 2s steps(47), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    font-family: monospace;
    font-size: 2em;
    margin-bottom: 20px;
}

@keyframes typing {
    from {
        width: 0;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

/* Button Styles */
.sign-in-button,
.create-account-button {
    background-color: #000000; /* Black background */
    color: white; /* White text */
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 25px; /* Rounded corners */
    cursor: pointer;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: 2s; /* Adjust delay as needed */
    margin-top: 20px; /* Add margin-top to move the button down */
}

.sign-in-button:hover,
.create-account-button:hover {
    transform: scale(1.1);
    background-color: white; /* White background on hover */
    color: black; /* Black text on hover */
}

.sign-in-button:focus,
.create-account-button:focus {
    outline: none;
}

.description {
    width: 478px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 20px;
    color: #1d1d1f; /* Dark text */
    font-family: SF Pro Display, Helvetica Neue, sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
    text-align: center;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: 2s; /* Adjust delay as needed */
}

.cards-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 40px;
    padding: 20px;
}

.card {
    background-color: #ffffff; /* White background */
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    width: 100%;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    animation: fadeIn 1s ease-in-out;
    position: relative;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

.card-icons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.card-icons img {
    width: 40px;
    height: 40px;
    animation: bounce 2s infinite;
}

.card-content h2 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
}

.card-content p {
    margin-top: 10px;
    color: #555;
    line-height: 1.5;
}

.card-content a {
    color: #1a73e8;
    text-decoration: none;
}

.card-content a:hover {
    text-decoration: underline;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
