/*Chandra Vamsi Krishna Alla 1002170054 Naga Manasa Bandaru : 1002195819 Venkata Harshitha Bathala : 1002204412 Pranav Subrahmanyeswararao Damu : 1002158458*/


/* Footer Styles */
.footer {
    background-color: black;
    color: white;
    padding: 40px 0;
    text-align: left;
    width: 100%; /* Ensure footer spans the full width */
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align items */
}

.footer-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%; /* Ensure grid spans the full width */
    justify-content: space-between; /* Distribute space between items */
}

.footer-grid > div {
    flex: 1;
    min-width: 200px;
}

.footer-grid h6 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.footer-grid a {
    color: white;
    text-decoration: none;
    display: block;
    margin-bottom: 5px;
    font-size: 1em;
}

.footer-grid a:hover {
    text-decoration: underline;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    width: 100%; /* Ensure the bottom text spans the full width */
}
