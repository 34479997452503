/*Chandra Vamsi Krishna Alla 1002170054 Naga Manasa Bandaru : 1002195819 Venkata Harshitha Bathala : 1002204412 Pranav Subrahmanyeswararao Damu : 1002158458*/


.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  .signup-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 480px;
    text-align: center;
  }
  
  .signup-form input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .signup-form button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .signup-form button:hover {
    background-color: #0056b3;
  }


.signup-page {
  background-image: url('https://static.vecteezy.com/system/resources/previews/025/552/448/original/holographic-gradient-textured-background-noisy-light-rainbow-gradation-soft-colors-grainy-foil-abstract-blurred-fluid-wallpaper-vector.jpg'); /* Path relative to the public directory */
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-form-container {
  background: rgba(255, 255, 255, 0.8); /* Slightly transparent background for the form */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 480px;
  width: 100%;
}



