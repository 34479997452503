/*Chandra Vamsi Krishna Alla 1002170054 Naga Manasa Bandaru : 1002195819 Venkata Harshitha Bathala : 1002204412 Pranav Subrahmanyeswararao Damu : 1002158458*/


.login-page {
  background-image: url('https://static.vecteezy.com/system/resources/previews/025/552/448/original/holographic-gradient-textured-background-noisy-light-rainbow-gradation-soft-colors-grainy-foil-abstract-blurred-fluid-wallpaper-vector.jpg'); /* Path relative to the public directory */
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  background: rgba(255, 255, 255, 0.8); /* Slightly transparent background for the form */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

.login-form {
  width: 100%;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #1565c0;
}
