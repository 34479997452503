/* src/components/Auth/Navbar.css */
/*Chandra Vamsi Krishna Alla 1002170054 Naga Manasa Bandaru : 1002195819 Venkata Harshitha Bathala : 1002204412 Pranav Subrahmanyeswararao Damu : 1002158458*/
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: black;
    color: white;
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .navbar-left .brand-name {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
  }
  
  .navbar-button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .navbar-button:hover {
    background-color: #e0e0e0;
  }
  
  .profile-container {
    position: relative;
    margin-left: 20px;
  }
  
  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1000;
    width: 250px;
  }
  
  .dropdown-menu .profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    background-color: #f0f0f0;
  }
  
  .dropdown-menu .profile-header .dropdown-profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .dropdown-menu .profile-header span {
    display: block;
    color: #333;
    font-weight: bold;
  }
  
  .dropdown-menu button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
  }
  
  .dropdown-menu button:hover {
    background-color: #f0f0f0;
  }
  