/*Chandra Vamsi Krishna Alla 1002170054 Naga Manasa Bandaru : 1002195819 Venkata Harshitha Bathala : 1002204412 Pranav Subrahmanyeswararao Damu : 1002158458*/


.email-item {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  background-color: #fff;
}

.email-item:hover {
  background-color: #f1f1f1;
}

.email-item-content h3 {
  margin: 0 0 5px 0;
  font-size: 1em;
}

.email-item-content p {
  margin: 0;
  color: #555;
  font-size: 0.9em;
}

.email-item-content small {
  display: block;
  margin-top: 5px;
  color: #999;
  font-size: 0.8em;
}
