/*Chandra Vamsi Krishna Alla 1002170054 Naga Manasa Bandaru : 1002195819 Venkata Harshitha Bathala : 1002204412 Pranav Subrahmanyeswararao Damu : 1002158458*/


.email-dashboard {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #f1f1f1;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.new-email-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.new-email-button:hover {
  background-color: #005a9e;
}

.folders ul {
  list-style-type: none;
  padding: 0;
}

.folders li {
  padding: 10px 0;
  cursor: pointer;
}

.folders li:hover {
  background-color: #e1e1e1;
}

.email-list {
  width: 300px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

.email-list h2 {
  margin: 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.email-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.email-content .placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.email-content .placeholder img {
  width: 100px;
  margin-bottom: 20px;
}
